import React from 'react'
import loadable from '@loadable/component'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import './vendor/bootstrap-grid.min.css'

const MainScreen = loadable(() => import('./views/Main'))

function AppRouter() {
  return (
    <Router>
      <Route path="/" exact component={MainScreen} />
      <Route path="/sponsor" exact render={() => window.location = "/sponsorship.pdf"} />
      <Route path="/volunteer" exact render={() => window.location = "https://forms.gle/p2MoQhtcfL9UtYCw6"} />
      <Route path="/hackweek" exact render={() => window.location = "https://www.facebook.com/events/694726147600663/"} />
      <Route path="/sponsor-info" exact render={() => window.location = "/sponsorship_schedule.pdf"} /> 
      <Route path="/booklet" exact render={() => window.location = "/booklet.pdf"} /> 
    </Router>
  )
}

export default AppRouter
